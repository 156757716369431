<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="policy">
      <div class="d-center">
        <div class="body-policy fadeIn">
          <h1 class="title-md">Data Privacy <span>policy</span></h1>
          <div class="my-2">
            <h4>What Personal Data do we collect?</h4>
            <p>
              GoBuilders Netsoft Sdn Bhd ("Golog") respects your right to
              privacy, and we keep your Data secure, and we will only use your
              personal information in the manner in which you want us to. This
              Privacy Policy describes how Golog collects, uses, processes, and
              discloses your Personal Data through our website, web app or
              mobile app, products, features, and other services. "Personal
              Data" is any information that can be used to identify you or from
              which you are identifiable. This includes but is not limited to
              your name, nationality, telephone, number, bank and credit card
              details, email address, your image, identity card number, driver
              license ID, biometric data, race, date of birth, marital status,
              religion, health information, vehicle, and insurance information.
            </p>
          </div>

          <div class="my-2">
            <h4>What Personal Data do we collect?</h4>
            <p>
              Any of the Personal Data we collect from you may be used in one of
              the following ways:
            </p>
            <ol>
              <li>
                To personalize your experience: identifying your preferences and
                behaviour to help us better respond to your individual needs.
              </li>
              <li>
                To improve our website: we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you;
              </li>
              <li>
                To improve customer service: your personal data helps us to
                respond to your customer service requests and support needs more
                effectively;
              </li>
              <li>To process transactions swiftly and securely;</li>
              <li>
                To perform internal operations to provide our services such as
                troubleshooting software bugs and operational problems,
                monitoring and analysing activity trends;
              </li>
              <li>
                To administer a contest, promotion, survey, or other site
                feature;
              </li>
              <li>
                Your Personal Data may be transferred from a country, state, and
                city where you are present while using our services to another
                country, state, and city. You understand and consent to the
                transfer of your Personal Data from your country to another
                country.
              </li>
            </ol>
          </div>

          <div class="my-2">
            <h4>Collection of Personal Data</h4>
            <p>
              Any of the Personal Data we collect from you may be used in one of
              the following ways:
            </p>
            <ol>
              <li>
                <h6>When you fill up demographic information in surveys.</h6>
                <ol>
                  <li>When you fill up demographic information in surveys.</li>
                  <li>When you provide a third party's Personal Data.</li>
                  <li>When you interact with our social media pages;</li>
                  <li>
                    When you participate in contests or events organized by us;
                  </li>
                  <li>When you fill up demographic information in surveys.</li>
                </ol>
              </li>
              <li class="mt-1">
                <h6>
                  Your Personal Data may be transferred from a country, state,
                  and city where you are present while using our services to
                  another country, state, and city. You understand and consent
                  to the transfer of your Personal Data from your country to
                  another country.
                </h6>
                <ol>
                  <li>
                    Your location (To detect pick-up and delivery locations);
                  </li>
                  <li>Feedback, ratings, and compliments;</li>
                  <li>
                    Transaction information (such as payment method and distance
                    travel);
                  </li>
                  <li>
                    Information about your experience using our services (such
                    as features used and content viewed);
                  </li>
                  <li>
                    Personal data, you enter in messages when you use our in-app
                    communication features.
                  </li>
                </ol>
              </li>
              <li>
                <h6>
                  We may collect your Data from other sources, but we will make
                  sure that the Data is transferred to us following applicable
                  laws wherein such sources would include:
                </h6>
                <ol>
                  <li>Referral programs;</li>
                  <li>Our business partners such as payment providers;</li>
                  <li>Insurance and financial providers;</li>
                  <li>Publicly available Data;</li>
                  <li>Governmental sources of Data;</li>
                  <li>When our users add you as emergency contact; and</li>
                  <li>Marketing services providers or partners.</li>
                </ol>
              </li>
              <li>
                <h6>When you provide a third party's Personal Data?</h6>
                <p>
                  You may provide a third party's Personal Data (such as your
                  spouse, family members, or friends) to us in some situations.
                  If you provide us with their Personal Data, you represent and
                  warrant that you have obtained their consent for their
                  Personal Data to be collected, used, processed, and disclosed
                  as set out in this Policy.
                </p>
              </li>
            </ol>
          </div>

          <div class="my-2">
            <h4>Personal Data of Minors</h4>
            <p>
              We do not sell, trade, or otherwise transfer to third parties your
              Personal Data. This does not include trusted third parties who
              assist us in operating our website, conducting our business, or
              servicing you, so long as those parties agree to keep this
              Personal Data confidential. We may also release your Personal Data
              when we believe release is appropriate to comply with the law,
              enforce our site policies to prevent or detect a crime, protect
              the public interest or protect ours or other rights, property, or
              safety. However, non-personally identifiable visitor information
              may be provided to other parties for marketing, advertising, or
              other uses.
            </p>
          </div>

          <div class="my-2">
            <h4>How do we protect your Personal Data?</h4>
            <p>
              To safeguard your Data from unauthorized access, collection, use,
              disclosure, processing, copying, modification, or similar risks,
              we have implemented a variety of security measures to maintain the
              safety of your personal information when you enter, submit, or
              access your personal information, which may include (but not
              limited to): SSL certificate signing, TLS encryption, and hashed
              password storage.
              <br />
              <br />
              Your password is the key to your account. Please use unique
              numbers, letters, and special characters and do not share your
              Golog password with anyone. If your password is leaked to a third
              party, you may lose control over your information and other data
              submitted to us, and you could also be subject to legally binding
              actions taken from your account. As such, if your password has
              been compromised, you should immediately change the password.
            </p>
          </div>
          <div class="my-2">
            <h4>Do we use cookies?</h4>
            <p>
              Google, as a third-party vendor, uses cookies to serve ads on your
              site. Google's use of the DART cookie enables it to serve ads to
              your users based on their visit to your sites and other sites on
              the Internet. Users may opt-out of using the DART cookie by
              visiting the Google ad and content network privacy policy.
            </p>
          </div>
          <div class="my-2">
            <h4>Updating Your Personal Data</h4>
            <p>
              It is important that the Personal Data you provide to us is
              accurate and complete to continue using our services. You are
              responsible for informing us of any changes to your Personal Data
              or in the event you believe that the Personal Data we have about
              you is inaccurate, incomplete, misleading, or out of date. You can
              update your Data anytime by accessing your account. If you cannot
              edit your Data through your account, you can contact our data
              protection department.
            </p>
          </div>
          <div class="my-2">
            <h4>Do we disclose any Personal Data to third parties?</h4>
            <p>
              We do not sell, trade, or otherwise transfer to third parties your
              Personal Data. This does not include trusted third parties who
              assist us in operating our website, conducting our business, or
              servicing you, so long as those parties agree to keep this
              Personal Data confidential. We may also release your Personal Data
              when we believe release is appropriate to comply with the law,
              enforce our site policies to prevent or detect a crime, protect
              the public interest or protect ours or other rights, property, or
              safety. However, non-personally identifiable visitor information
              may be provided to other parties for marketing, advertising, or
              other uses.
            </p>
          </div>
          <div class="my-2">
            <h4>Disclosure of Personal Data</h4>
            <p>
              The Personal Data which you have provided to us will generally be
              kept confidential, but your Personal Data may be disclosed to the
              following categories of parties:
            </p>
            <ol>
              <li>
                Any person to whom we are compelled or required to do so under
                law or in response to a government agency;
              </li>
              <li>Government agencies and statutory authorities;</li>
              <li>
                Our auditors, consultants, accountants, or other financial or
                professional advisors;
              </li>
              <li>
                Any other individuals or organizations with your consent or
                instructions;
              </li>
              <li>Our subsidiaries and our associated companies;</li>
              <li>
                Our service providers and business partners such as our vendors,
                consultants, marketing partners, research firms, payment
                processors and facilitators, data analytics providers, and
                financial and insurance partners;
              </li>
              <li>
                If you are a customer, we will share the location of the pick-up
                and delivery locations with the drivers;
              </li>
              <li>
                If you are a driver, we will share your Personal Data with the
                customer, including your name and photo, vehicle make and model,
                number plate, location, and your average rating;
              </li>
            </ol>
          </div>
          <div class="my-2">
            <h4>Client's Right of Access and Correction</h4>
            <p>
              Subject to registration, you have the right to request access to
              and correct your Personal Data held by us. In this respect, you
              may:
            </p>

            <ol>
              <li>
                Check whether we hold or use your Personal Data and request
                access to such data;
              </li>
              <li>
                Request that we specify or explain our policies and procedures
                about Data and the types of Personal Data handled by us; and
              </li>
              <li>
                Request that your Personal Data is retained by us for only as
                long as necessary for the fulfilment of the purposes for which
                it was collected;
              </li>
              <li>
                Request that we specify or explain our policies and procedures
                about Data and the types of Personal Data handled by us; and
              </li>
              <li>
                Withdraw, in full or in part, your consent given previously, in
                each case subject to any applicable legal restrictions,
                contractual conditions, and a reasonable period. However, if you
                withdraw your consent, it could affect our provision of services
                to you.
              </li>
            </ol>
            <p>
              The original language in drafting this Privacy Policy is the
              English language. In any conflict between the English and other
              language versions, the English version shall prevail. We shall
              have the right to modify, update, or amend the terms of this
              Privacy Policy at any time by placing the revised Privacy Policy
              on our website.
            </p>
          </div>
          <div class="my-2">
            <h4>Retention of Personal Data</h4>
            <p>
              We will only retain your data for as long we are either required
              to by law or as is relevant for the purposes for which it was
              collected.
              <br />
              <br />
              We will cease to retain your Data or remove how the Personal Data
              can be associated with you as soon as it is reasonable to assume
              that such retention no longer serves the purposes for which the
              information was collected and is no longer necessary for any legal
              or business purposes.
            </p>
          </div>
          <div class="my-2">
            <h4>International Transfer of Personal Data</h4>
            <p>
              Your Personal Data may be transferred from a country, state, and
              city where you are present while using our services to another
              country, state, and city. You understand and consent to the
              transfer of your Personal Data from your country to another
              country.
            </p>
          </div>
          <div class="my-2">
            <h4>Your Consent</h4>
            <p>
              By disclosing your Data to us, you agree and consent to processing
              your Personal Data for the purposes stated in this Privacy Policy.
              Nevertheless, you still have the right to withdraw your consent or
              restrict the purpose(s) and methods in which we process your
              Personal Data and the Personal Data relating to other persons who
              may be identified from your Personal Data at any time following
              the terms of this Privacy Policy.
            </p>
          </div>
          <div class="my-2">
            <h4>Third-Party Links</h4>
            <p>
              Occasionally, we may include or offer third-party products or
              services on our website at our discretion. These third-party sites
              have separate and independent privacy policies. We, therefore,
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our site and welcome any feedback about these sites.
            </p>
          </div>
          <div class="my-2">
            <h4>Terms and Conditions</h4>
            <p>
              Please also visit our Terms and Conditions section establishing
              the use, disclaimers, and limitations of liability governing our
              website's use at www.glogc.com.
            </p>
          </div>
          <div class="my-2">
            <h4>Changes to Our Privacy Policy</h4>
            <p>
              If we decide to change our privacy policy, we will post those
              changes on this page.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0);
  },
};
</script>
